const customColors = {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#F6FAFF",
      100: "#EFF2FB",
      200: "#D9E2EC",
      300: "#BCCCDC",
      400: "#9FB3C8",
      500: "#829AB1",
      600: "#486581",
      700: "#334E68",
      800: "#243B53",
      900: "#102a43",
    },
    primary: {
      50: "#FFE0FF",
      100: "#F8CCFF",
      200: "#EB99FF",
      300: "#D766FF",
      400: "#C23FFF",
      500: "#9F00FF",
      600: "#7B00DB",
      700: "#5C00B7",
      800: "#410093",
      900: "#2E007A",
    },
    blue: {
      50: "#DFFFFF",
      100: "#CBFEF0",
      200: "#97FDEB",
      300: "#63F9EB",
      400: "#3DF4F2",
      500: "#00DCED",
      600: "#00ACCB",
      700: "#0082AA",
      800: "#005D89",
      900: "#004471",
    },
    red: {
      50: "#FFE0E0",
      100: "#FFCCCC",
      200: "#FF99A1",
      300: "#FF6681",
      400: "#FF3F75",
      500: "#FF0061",
      600: "#DB0069",
      700: "#B7006A",
      800: "#930064",
      900: "#7A005F",
    },
    yellow: {
      50: "#FFFFE6",
      100: "#FFF8D2",
      200: "#FFEFA5",
      300: "#FFE379",
      400: "#FFD857",
      500: "#FFC620",
      600: "#DBA417",
      700: "#B78310",
      800: "#93650A",
      900: "#7A5006",
    },
    green: {
      50: "#DFFFEE",
      100: "#CBFEDA",
      200: "#97FDC1",
      300: "#63F9AF",
      400: "#3DF4AB",
      500: "#00EDA6",
      600: "#00CBA2",
      700: "#00AA98",
      800: "#008988",
      900: "#006771",
    },
    mode: {
      light: {
        logo: "#102a43",
        background: "#fff",
        cardBG: "#EFF2FB",
        text: "#334E68",
        heading: "#102a43",
        icon: "#9FB3C8",
        link: "#9F00FF",
      },
      dark: {
        logo: "#ffffff",
        background: "#102a43",
        cardBG: "#000000",
        text: "#EFF2FB",
        heading: "#fff",
        icon: "#486581",
        link: "#F8CCFF",
      },
    },
  }
  
  export default customColors
  